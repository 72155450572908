<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row class="">
      <v-col
        offset-md="1"
        class="text-body-2   ps-9 px-sm-16  font-weight-medium mt-10 mt-sm-0"
        :class="`text-body-1 font-weight-medium`"
        :align-self="$vuetify.breakpoint.smAndUp ? 'center' : ''"
      >
        <v-card class="rounded-lg pa-6">
          <v-row
            justify="center"
            class="mt-20 mb-16 text-md-h4  mt-sm-0 font-weight-bold"
            >Agreement & Terms and conditions of use
          </v-row>

          <v-row class="mt-7 ">
            <v-col cols="12">
              <p class="mb-8">
                <strong> 1. Agreement:</strong><br />
                1.1 These Agreement, terms and conditions and the privacy policy
                together constitute a legal binding agreement
                <strong>("Agreement")</strong>
                between <strong>(Macro)</strong> and you and we mean by
                <strong>("You")</strong> whether you are a client or a user of
                the platform.
              </p>
              <p class="mb-8">
                <strong>2.Law and Jurisdiction:</strong><br />
                2.1 Your acceptance of these terms is considered to be an
                approved and signed electronic signature in our electronic
                system and confirms the validity of the agreement, by applying
                the provisions of Law No. 20/2014 for electronic transactions,
                and all electronic data, information provided, offered,
                submitted, presented, shared, kept, used in or through the
                application are governed with the terms of the regulation
                42/2021 in regards to privacy protection.<br />
                2.2 This agreement and terms and conditions shall be governed by
                and construed in accordance with [Kuwaiti laws].<br />
                2.3 Any disputes relating to this agreement and/or terms and
                conditions shall be subject to the jurisdiction of the courts of
                (Kuwait).
              </p>
              <p class="mb-8">
                <strong>3. Macro:</strong><br />
                3.1 Macro Company is a technology and Solution providing
                Company; Macro focuses on automation solutions, IT Solutions and
                Services. and, deals with the Information and technology
                platform of Macro the next-generation Information Technology
                Services company who delivers innovative IT solutions & IT
                enabled services precisely addressing business needs of our
                clients. We provide business and technology consulting, custom
                software development, web design & development, maintenance,
                systems integration, testing and business process outsourcing
                etc. We have chosen a strategic global model combining the best
                of onshore and offshore processes to deliver premium quality
                services and products to our clients. Focus on business needs of
                our clients, team Macro technical expertise and domain knowledge
                has enabled Macro to become a valued business partner for a host
                of reputed customers across the globe.
              </p>
              <p class="mb-8">
                <strong>4. License to use the platform:</strong><br />
                4.1 You may:<br />
                <span class="pl-4">(a) View pages from the platform;</span>
                <br />
                <span class="pl-4"
                  >(b) Download pages from the platform for caching; </span
                ><br />
                <span class="pl-4"> (c) Print pages from the platform;</span
                ><br />
                <span class="pl-4">
                  (d) Use the platform services, subject to the other provisions
                  of these terms and conditions.</span
                ><br />

                4.2 Except as expressly permitted by the provisions of this
                agreement and terms and conditions, you must not download any
                material from the platform or save any such material to the
                device.<br />
                4.3 You may only use the platform for your own personal use, and
                you must not use the platform for any other purposes.<br />
                4.4 Except as expressly permitted by these terms and conditions,
                you must not edit or otherwise modify any material on the
                platform.<br />
                4.5 Unless you own or control the relevant rights in the
                material, you must not:<br />

                <span class="pl-4">
                  (a) Republish material from the platform (including
                  republication on another platform);</span
                ><br />
                <span class="pl-4">
                  (b) Sell, rent or sub-license material from the
                  platform;</span
                ><br />
                <span class="pl-4">
                  (c) Show any material from the platform in public;</span
                ><br />
                <span class="pl-4">
                  (d) Exploit material from the platform for a commercial
                  purpose; or redrafting.</span
                ><br />
                <span class="pl-4">
                  (e) Redistribute material from the platform.</span
                ><br />
                4.5 We reserve the right to restrict access to areas of the
                platform, or indeed the whole platform, at your discretion; you
                must not circumvent or bypass, or attempt to circumvent or
                bypass, any access restriction measures on the platform.<br />
              </p>
              <p class="mb-8">
                <strong>5. Acceptable Use:</strong><br />
                5.1 You must not:<br />
                <span class="pl-4">
                  (a) Use the platform in any way or take any action that
                  causes, or may cause, damage to the platform or impairment of
                  the performance, availability or accessibility of the
                  platform;</span
                ><br />
                <span class="pl-4">
                  (b) Use the platform in any way that is unlawful, illegal,
                  fraudulent or harmful, or in connection with any unlawful,
                  illegal, fraudulent or harmful purpose or activity;</span
                ><br />
                <span class="pl-4">
                  (c) use the platform to copy, store, host, transmit, send,
                  use, publish or distribute any material which consists of (or
                  is linked to) any spyware, mobile or computer virus, Trojan
                  horse, worm, keystroke logger, rootkit or other malicious
                  mobile or computer software;</span
                ><br />
                <span class="pl-4">
                  (d) Conduct any systematic or automated data collection
                  activities (including without limitation scraping, data
                  mining, data extraction and data harvesting) on or in relation
                  to the platform without the express written consent;</span
                ><br />
                <span class="pl-4">
                  (e) Violate the directives set out in the robots.txt file for
                  the platform; or</span
                ><br /><span class="pl-4">
                  (f) Use of data collected from the app for any direct
                  marketing activity (including but not limited to email
                  marketing, SMS marketing, telemarketing, social media
                  marketing and sponsor ads and direct mail.</span
                ><br />
                5.2 You must not use data collected from the platform to contact
                individuals, companies or other persons or entities.<br />5.3
                You must ensure that all the information you supply to us
                through the platform, or in relation to the platform, is true,
                accurate, current, complete and non-misleading.
              </p>
              <p class="mb-8">
                <strong>6. Clients:</strong><br />
                All Macro clients.
              </p>

              <p class="mb-8">
                <strong>7. User IDs and passwords</strong><br />
                7.1 If you register for an account with the platform, you will
                be asked to enter your [name. e-mail, phone number, birthdate
                and a password].<br />
                7.2 Your information must not be liable to mislead and you must
                not use your information for or in connection with the
                impersonation of any person.<br />
                7.3 You must keep your password confidential.<br />
                7.4 You must notify the platform management in writing
                immediately if you become aware of any disclosure of your
                password.<br />
                7.5 You are responsible for any activity on the platform arising
                out of any failure to keep your password confidential, and may
                be held liable for any losses arising out of such a failure.<br />
              </p>
              <p>
                <strong>8. Cancellation and Suspension of Account:</strong
                ><br />
                8.1 We may:<br />
                <span class="pl-4"> (a) [Suspend your account;]</span><br />
                <span class="pl-4"> (b) [cancel your account; and/or]</span
                ><br />
                <span class="pl-4"> (c) [Edit your account details,]</span
                ><br />
                At any time in our sole discretion without notice or
                explanation.<br />

                8.2 You may cancel your account on the platform using your
                account control panel on the platform.

                <br />
              </p>
              <p class="mb-8">
                <strong>9. Your content: rules</strong><br />
                9.1 You warrant and represent that your content will comply with
                this agreement and terms and conditions.<br />
                9.2 Your content must not be illegal or unlawful, must not
                infringe any person’s legal rights, and must not be capable of
                giving rise to legal action against any person (in each case in
                any jurisdiction and under any applicable law).<br />
                9.3 Your content, and the use of your content by us in
                accordance with this agreement and terms and conditions, must
                not:<br />

                <span class="pl-4"> (a) Be libelous or maliciously false;</span
                ><br />
                <span class="pl-4"> (b) Be obscene or indecent;</span><br />
                <span class="pl-4">
                  (c) Infringe any copyright, moral right, database right, trade
                  mark right, design right, right in passing off, or other
                  intellectual property right;</span
                ><br />
                <span class="pl-4">
                  (d) Infringe any right of confidence, right of privacy or
                  right under data protection legislation;</span
                ><br />
                <span class="pl-4">
                  (e) Constitute negligent advice or contain any negligent
                  statement;</span
                ><br />
                <span class="pl-4">
                  (f) Constitute an incitement to commit a crime, instructions
                  for the commission of a crime or the promotion of criminal
                  activity;</span
                ><br />
                <span class="pl-4">
                  (g) Be in contempt of any court, or in breach of any court
                  order;</span
                ><br />
                <span class="pl-4">
                  (h) Be in breach of racial or religious hatred or
                  discrimination legislation;<br />
                  <span class="pl-4"> (i) Be blasphemous;</span><br />
                  <span class="pl-4">
                    (j) Be in breach of official legislation;</span
                  ><br />
                  <span class="pl-4">
                    (k) Be in breach of any contractual obligation owed to any
                    person;</span
                  ><br />
                  <span class="pl-4">
                    (l) Depict violence in an explicit, graphic or gratuitous
                    manner;</span
                  ><br />
                  <span class="pl-4">
                    (m) Be pornographic, lewd, suggestive or sexually
                    explicit;</span
                  ><br />
                  <span class="pl-4">
                    (n) Be untrue, false, inaccurate or misleading;</span
                  ><br />
                  <span class="pl-4">
                    (o) Consist of or contain any instructions, advice or other
                    information which may be acted upon and could, if acted
                    upon, cause illness, injury or death, or any other loss or
                    damage;</span
                  ><br />
                  <span class="pl-4"> (p) Constitute spam;</span><br />
                  <span class="pl-4"></span>(q) Be offensive, deceptive,
                  fraudulent, threatening, abusive, harassing, anti-social,
                  menacing, hateful, discriminatory or inflammatory; or</span
                ><br />
                <span class="pl-4"
                  >(r) Cause annoyance, inconvenience or needless anxiety to any
                  person.</span
                ><br />
              </p>
              <p class="mb-8">
                <strong>10. Liability:</strong><br />
                10.1 Nothing in a contract in regards to this agreement and
                terms and conditions will:<br />
                <span class="pl-4">
                  (a) Limit or exclude any liability for death or personal
                  injury resulting from negligence;</span
                ><br />
                <span class="pl-4">
                  (b) Limit or exclude any liability for fraud or fraudulent
                  misrepresentation;</span
                ><br />
                <span class="pl-4"
                  >(c) Limit any liabilities in any way that is not permitted
                  under applicable law; or</span
                ><br />
                <span class="pl-4"
                  >(d) Exclude any liabilities that may not be excluded under
                  applicable law.</span
                ><br />
                <span class="pl-4"
                  >(e) govern all liabilities arising in regards to this
                  agreement and terms and conditions or relating to the subject
                  matter of that contract, including liabilities arising in
                  contract, in tort (including negligence) and for breach of
                  statutory duty, except to the extent expressly provided
                  otherwise in that contract.</span
                ><br />
                10.2 To the extent that the platform and the information and
                services on the platform are provided free of charge, we will
                not be liable for any loss or damage of any nature.<br />
                10.3 We will not be liable to you in respect of any losses
                arising out of any event or events beyond our reasonable
                control.<br />
                10.4 We will not be liable to you in respect of any business
                losses, including (without limitation) loss of or damage to
                profits, income, revenue, use, production, anticipated savings,
                business, contracts, commercial opportunities or goodwill.<br />
                10.5 We will not be liable to you in respect of any loss or
                corruption of any data, database or software.<br />
                10.6 We will not be liable to you in respect of any special,
                indirect or consequential loss or damage.<br />
                10.7 You accept that we have an interest in limiting the
                personal liability of our officers and employees and, having
                regard to that interest, you acknowledge that we are a limited
                liability entity; you agree that you will not bring any claim
                personally against our officers or employees in respect of any
                losses you suffer in connection with the platform or this
                agreement and terms and conditions (this will not, of course,
                limit or exclude the liability of the limited liability entity
                itself for the acts and omissions of our officers and
                employees).
              </p>
              <p class="mb-8">
                <strong
                  >11. Breaches of this agreement and terms and
                  conditions</strong
                ><br />
                11.1 Without prejudice to our other rights under this agreement
                and terms and conditions, if you breach this agreement and terms
                and conditions in any way, or if we reasonably suspect that you
                have breached this agreement and terms and conditions in any
                way, we may:<br />
                <span class="pl-4">
                  (a) Send you one or more formal warnings;</span
                ><br />
                <span class="pl-4">
                  (b) Temporarily suspend your access to the platform</span
                ><br />
                <span class="pl-4">
                  (c) Permanently prohibit you from accessing the
                  platform.</span
                ><br />
                <span class="pl-4">
                  (d) Block computers/mobile devices using your IP address from
                  accessing the platform</span
                ><br />
                <span class="pl-4">
                  (e) Contact any or all of your internet service providers and
                  request that they block your access to the platform.</span
                ><br />
                <span class="pl-4">
                  (f) Commence legal action against you, whether for breach of
                  this agreement and terms and conditions or otherwise;
                  and/or</span
                ><br />
                <span class="pl-4">
                  (g) Suspend or delete your account on the platform.</span
                ><br />
              </p>
              <p class="mb-8">
                <strong>12. Variation:</strong><br />
                12.1 We may revise and amend this agreement and terms and
                conditions from time to time.<br />
                12.2 The revised agreement and terms and conditions shall apply
                to the use of the platform from the date of publication of the
                revised agreement and terms and conditions on the platform, and
                you hereby waive any right you may otherwise have to be notified
                of, or to consent to, revisions of this agreement and terms and
                conditions. / We will give you written notice [by email, or any
                method that we decide] of any revision of this agreement and
                terms and conditions, and the revised agreement and terms and
                conditions will apply to the use of the platform from the date
                that we give you such notice; if you do not agree to the revised
                agreement and terms and conditions, you can contact the platform
                administration, or you can stop using the platform.<br />
                12.3 If you have given your express acceptance to this agreement
                and terms and conditions, we will ask for your express
                acceptance to any revision of this agreement and terms and
                conditions; and if you do not give your express acceptance to
                the revised agreement and terms and conditions within such
                period as we may specify, we will disable or delete your account
                on the platform, and you must stop using the platform.
              </p>

              <p class="mb-8">
                <strong>13. Severability:</strong><br />
                13.1 If a provision of this agreement and terms and conditions
                is determined by any court or other competent authority to be
                unlawful and/or unenforceable, the other provisions will
                continue in effect.<br />
                13.2 If any unlawful and/or unenforceable provision of this
                agreement and terms and conditions would be lawful or
                enforceable if part of it were deleted, that part will be deemed
                to be deleted, and the rest of the provision will continue in
                effect.
              </p>

              <p class="mb-8">
                <strong>14. Third party rights:</strong><br />
                14.1 This agreement and terms and conditions is for our benefit
                and your benefit, and is not intended to benefit or be
                enforceable by any third party.<br />
                14.2 The exercise of the parties’ rights under this agreement
                and terms and conditions is not subject to the consent of any
                third party.
              </p>

              <p class="mb-8">
                <strong>15. Entire agreement:</strong><br />
                15.1 This agreement and terms and conditions, together with the
                privacy policy, shall constitute the entire agreement between
                you [User, Client,] and Macro in relation to your use of the
                platform and shall supersede all previous agreements between you
                and Macro in relation to your use of the platform.
              </p>

              <p class="mb-8">
                <strong>16. Macro details:</strong><br />
                16.1 This platform is owned and operated by [Macro].<br />
                16.2 We are registered in [Kuwait],and our registered office is
                at Kuwait.<br />
                16.3 Our principal place of business is at Kuwait
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PrivacyPolicyPage"
};
</script>

<style scoped>
/* Add any additional styling for the Privacy Policy page here */
</style>
